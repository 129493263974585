<template>
    <v-footer
        color="primary"
        style="z-index:50; position: inherit;"
    >
        <div class="d-flex align-center w-100">
            <div v-if="show_contact_page"  class="px-2">
                <NuxtLink to="/contact">
                    <span class="text-info">Nous contacter</span>
                </NuxtLink>
            </div>
            <div v-if="show_rgpd_page" class="px-2">
                <NuxtLink to="/donnees-personnelles">
                    <span class="text-info">Données personnelles</span>
                </NuxtLink>
            </div>
            <v-spacer />
            <div v-if="$refs.tag?.api_env === 'PRODUCTION'" class="px-2">
                <a :href="`https://uptime.datakode.fr/status/hydraclic${$config.public.code_departement}`" target="_blank" class="text-info">
                    Status des services
                </a>
            </div>
            <div class="px-2">
                © {{ new Date().getFullYear() }} Hydraclic <version-tag ref="tag" />
            </div>
        </div>
    </v-footer>
</template>

<script lang="ts">

import { useConfigStore } from '~/store'
import VersionTag from '@nuxtDatakodeUtils/components/footer/VersionTag.vue'

export default {
    components: { VersionTag },
    setup() {
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data() {
        return {
            show_contact_page: this.configStore.currentConfig.show_contact_page,
            show_rgpd_page: this.configStore.currentConfig.show_rgpd_page,
            api_version: '',
            api_env: 'PRODUCTION',
            releases_url: null,
        }
    },
    mounted() {
        const api = this.$api as ApiService;
        api.setRequest({
            url: '',
            method: 'GET',
        }).then(async (data) => {
            this.api_version = 'v' + data?.pretty_version ?? '?'
            this.api_env = data?.env?.toUpperCase()
            this.releases_url = data?.releases_url
        })
    },
}
</script>
